import React from 'react'
import { Link } from 'react-router-dom';


export const PDani = () => {
  return (

<div>
    <div>dani.com</div> 
    
    <a href="/www.preguntaleadani.com/index.html" >aqui carga el componente </a>
     </div>
  )
}
export default PDani
 








