import React from 'react'

import '../css/Trayectoria.css';
//import '../js/portafolioImg';

//import $ from 'jquery';
//import { findDOMNode } from 'react-dom';
//import Myimage from '../asset/model_bw.png'; //imagen de prueba 



const portafolio = () => {
  return (

    

    <>
  
   <div className='trayectoria'>
  <h1>Trayectoria</h1>
    <ul className="timeline">
    
     
      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            <span className="flag">Soporte</span>
            <span className="time-wrapper"><span className="time">2012 - 2023 </span></span>
          </div>
          <div className="desc">Brindo asistencia técnica y resuelvo problemas relacionados
           con el funcionamiento de los sistemas informáticos. Me encargo de diagnosticar  y 
           solucionar fallas tanto en hardware como en Software, ofreciendo orientación y capacitación a los usuarios.
            Mi objetivo principal es garantizar un funcionamiento eficiente y sin interrupciones
             de los sistemas, para que los usuarios puedan realizar
              sus tareas diarias de manera efectiva.</div>
        </div>
      </li>
      
      
      <li>
        <div className="direction-l">
          <div className="flag-wrapper">
            <span className="flag">Líder</span>
            <span className="time-wrapper"><span className="time">2022 - 2023</span></span>
          </div>
          <div className="desc">Como administrador de sistemas, mi trabajo es garantizar que los sistemas 
          informáticos de una organización funcionen de manera eficiente y segura. Me encargo de la
           instalación, configuración y mantenimiento de los servidores, redes y software necesarios 
           para que todo el equipo de trabajo pueda realizar sus tareas sin problemas.
            También me encargo de la seguridad de los datos y de garantizar que los sistemas 
            estén actualizados y en buen estado de funcionamiento. En resumen,
             mi objetivo es asegurar el correcto funcionamiento de los sistemas 
             informáticos de la empresa para optimizar la productividad y
              el rendimiento de todos los usuarios.</div>
        </div>
      </li>
    
     
      <li>
            <div className="direction-r">
              <div className="flag-wrapper">
                <span className="flag">Desarrollador</span>
                <span className="time-wrapper"><span className="time">2020 - 2023</span></span>
              </div>
              <div className="desc">Como desarrollador web, creo sitios web funcionales y atractivos.
                Utilizo tecnologías como HTML, CSS y JavaScript, ReactJS, Node.js, Laravel  y base de datos en SQL Y MySQL   para diseñar y desarrollar
                la estructura y la apariencia de los sitios web. Además, también me encargo de optimizar él
                rendimiento de los sitios web, asegurándome de que sean rápidos y se adapten a diferentes
                dispositivos y navegadores.
                También me aseguro de que la lógica y la base de datos detrás de la aplicación estén bien estructuradas.
                Mi objetivo principal es garantizar que el producto final cumpla con los requisitos del cliente y
                brinde una experiencia de usuario excepcional. Trabajo en estrecha colaboración con otros miembros
                del equipo para asegurar la calidad y la eficiencia en cada proyecto.</div>
            </div>
          </li>

        </ul>

      </div>
</>


  )
}

export default portafolio