import { render } from '@testing-library/react'
import React from 'react';



class descripcion extends React.Component {
    render() {
        return (

            <div class="landing">
            <div class="intro">
                <h3>Ingeniero <span>Dairo Rivera</span><br />Admin De Sistemas<br />Developer-Servers-Support</h3>
                <button>Contactame</button>
            </div>

           {/*} <!--   <div class="socials">
                <div class="line"></div>
                <i class="ri-facebook-circle-fill"></i>
                <i class="ri-twitter-fill"></i>
                <i class="ri-linkedin-fill"></i>
                <i class="ri-whatsapp-line"></i>
            </div>
        -->*/}




        </div>     

         

       

     );
   }
}
export default descripcion;