import React from 'react'
import '../css/footer.css';

const footer = () => {
  return (

<>
    
          <footer>

              <div className="waves">
                  <div className="wave" id="wave1"></div>
              </div>
              <ul className="social_icon">
                  <li><a href="https://www.linkedin.com/in/dairo-rivera-ramirez-7563bb152/" className="fa fa-linkedin">
                  </a></li>
              </ul>

              <p>©2023 By | Todos Los Derechos Reservados </p>
          </footer>
          <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
          <script noModule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </>
  )
}

export default footer