import { render } from '@testing-library/react'
import React from 'react';
import myImage from '../asset/images/logo1.png';


class Menu extends React.Component {
    render() {
        return (


           
                <nav>
                
                   <img src={myImage} alt="" />
                    <ul>
                        <li><a href="#skills-loc" className="active">Skills</a></li>
                        {/*<li><a href="#portfolio-loc">Portafolio</a></li>*/}
                        <li><a href="#Trayectoria-loc">Trayectoria</a></li>
                        <li><a href="#contact-loc">Contáctame</a></li>
                    </ul>

                </nav>
            

     );
   }
}
export default Menu;