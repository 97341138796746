import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Link, Routes } from 'react-router-dom';

import Menu from './Componets/Menu';//menu del body
import Footer from './Componets/footer';
import body from './Componets/Descripcion'; //mi descripcion
import PDani from './Componets/Dani';
import Trayectoria from './Componets/Trayectoria'; //carga de componente pediente 
import './css/normalize.css'; //Reset de stilos para otros navegadores
import './css/style.css';
const Skills = require.context('./asset/skills', true);//Carga imagenes
const Portafolio = require.context('./asset/portafolio', true);//carga de img 





function App() {
  return (

    <>

      <div className='contenedor' id='contenedor'>

        <header>

          <div>

            <Menu />

          </div>

          <div className="landing">
            <div className="intro">
              <h3>Ingeniero <span>Dairo Rivera</span><br />Admin De Sistemas<br />Cloud - Infraestructura - Servers</h3>
              <button>Contactame</button>
            </div>
          </div>

        </header>


    
    <div>
      
         <Router>
           <Routes>
              <Route exact path='/Dani' Component={PDani} />
             



           </Routes>


         </Router>
       
    
    </div>
      






        <section className="about">
          <h1>About</h1>
          <div className="description">
            <div className="line"></div>
            <p>Soy un ingeniero de sistemas altamente capacitado con una amplia experiencia en el despliegue y
              gestión de servidores en entornos Windows, Linux y Mac. Mi experiencia se extiende a la
              implementación y mantenimiento de servicios en la nube, incluyendo Azure IaaS, PaaS y SaaS, lo
              que me permite llevar a cabo proyectos de infraestructura de alto nivel.<br />
              Además, he demostrado mis habilidades en la administración de Microsoft 365, lo que garantiza un entorno de
              trabajo productivo y colaborativo. Mi conocimiento en redes de datos me permite diseñar,
              configurar y mantener redes eficientes y seguras.<br />
              <span>La ciberseguridad es una de mis principales prioridades, y cuento con experiencia en implementar medidas de seguridad
                robustas para proteger la integridad de los datos y sistemas.</span> <br />
              Mi objetivo es seguir creciendo como profesional y
              contribuir al éxito de proyectos tecnológicos en todas las áreas mencionadas.
              Si buscas un ingeniero de sistemas versátil y comprometido con el éxito de tu empresa,
              estoy seguro de que puedo aportar las habilidades y experiencia necesarias para lograrlo.</p>
          </div>
        </section>



        <section className="skills" id="skills-loc">
          <h1>My Skills</h1>
          <div className="cards">
            <div className="skill-card">
              {/*} <img src="" alt="" />*/}
              <img src={Skills('./awsvsazure.png')} />
              <h2>Azure - AWS</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./win-server-logo.png')} alt='' />
              <h2>Server</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./logo-microsoft-365.png')} />
              <h2>Tenant 365</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./linuxWm.png')} />
              <h2>S.O</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./kubernetes.png')} />
              <h2>Kubernetes</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./Terraform-logo.png')} />
              <h2>Terraform</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./git.svg')} />
              <h2>GIT</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./GitHub-logo.png')} />
              <h2>GitHub</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./visual-studio-code.png')} />
              <h2>Visual Studio</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./react.svg')} />
              <h2>ReactJS</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./nodejs.svg')} />
              <h2>NodeJS</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./html-css-js.png')} />
              <h2>HTML</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./Logo_php.png')} />
              <h2>PHP</h2>
            </div>
            <div className="skill-card">

              <img src={Skills('./mysql.svg')} />
              <h2>MYSQL</h2>
            </div>
          </div>
        </section>




        {/*
      <section className="portfolio" id="portfolio-loc">
        <h1>Portafolio</h1>
        <div className="portfolio-cards">
          <div className="portfolio-card">
            
            <img src={Portafolio('./1.1.jpg')} />
            <div className="overlay">
              <h3>Tecnologías Cloud</h3>
              <p>Mi trabajo en la nube implica utilizar recursos de computación,
                almacenamiento y servicios a través de Internet. Esto me permite acceder
                a aplicaciones y datos desde cualquier lugar y en cualquier momento,
                sin tener que gestionar infraestructuras físicas. </p>
              
            </div>
          </div>
          <div className="portfolio-card">
            <img src={Portafolio('./pd.png')} />
            <div className="overlay">
              <h3>Developer Web</h3>
              <p>Proyectos como Desarrollador Web.</p>
              <button>Ver Más. </button>
            </div>
          </div>
          <div className="portfolio-card">
            <img src={Portafolio('./3.3.jpg')} />
            <div className="overlay">
              <h3>Servidores</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, laudantium!</p>
              <button>See More </button>
            </div>
          </div>
          <div className="portfolio-card">
            <img src={Portafolio('./4.jpg')} />
            <div className="overlay">
              <h3>Twitter Scrapper</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, laudantium!</p>
              <button>See More </button>
            </div>
          </div>
          <div className="portfolio-card">
            <img src={Portafolio('./5.jpg')} />
            <div className="overlay">
              <h3>LinkedIn Scrapper</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, laudantium!</p>
              <button>See More</button>
            </div>
          </div>
          <div className="portfolio-card">
            <img src={Portafolio('./6.jpg')} />
            <div className="overlay">
              <h3>YouTube Scrapper</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia, laudantium!</p>
              <button>See More</button>
            </div>
          </div>
        </div>

      </section>

  */}


        {/*portafolio nuevo*/}


        <section className="Trayectoria" id="Trayectoria-loc">


          <Trayectoria />

        </section>




        {/*fin del portafolio */}




        <section className="contact-me" id="contact-loc">
          
        <h1>Contáctame</h1>
          <section className="buttons">
           
          </section>
          
        </section>

      </div>
        
       <Footer /> 
       
    </>
    

  );
}

export default App;



